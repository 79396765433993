





































































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
const Auth = namespace("Auth");

@Component
export default class Login extends Vue {
    private user: any = { username: "", password: "" };
    private loading: boolean = false;
    private message: string = "";

    // Initial data can be declared as instance properties
    private pageTitle: string = 'Login';

    @Auth.Getter
    private isLoggedIn!: boolean;

    @Auth.Action
    private login!: (data: any) => Promise<any>;

    @Auth.Action
    public signOut!: () => void;

    created() {
        console.log('login');
        //console.log(this.isLoggedIn);
        if (this.isLoggedIn) {
            const loggedIn = localStorage.getItem('user');
            if (loggedIn != null)
                this.$router.push("/");
            else
                this.signOut();
        }
        // nascondo la scrollbar
        //this.$emit('sideBarHide', false);

        // set page title
        //document.title = "Login";
        //this.$emit('pageTitle', 'Login');
        
    }

    handleLogin() {
        this.loading = true;
        this.$validator.validateAll().then((isValid) => {
            if (!isValid) {
                this.loading = false;
                return;
            }
            
            if (this.user.username && this.user.password) {
                this.login(this.user).then(
                    (data) => {
                        window.location.href = "/";
                    },
                    (error) => {
                        this.loading = false;
                        this.message = error;
                    }
                );
            }
        });
    }
}
