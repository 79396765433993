import Vue from 'vue';
import App from './App.vue';

import router from "./Router"

import store from './store';

import VueSidebarMenu from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
Vue.use(VueSidebarMenu)

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// Install BootstrapVue

Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

//import Vuelidate from "vuelidate"
//Vue.use(Vuelidate as any)

import { BAlert } from 'bootstrap-vue'
Vue.component('b-alert', BAlert)

import VueHtmlToPaper from 'vue-html-to-paper';

import { isJwtExpired } from 'jwt-check-expiration';

const options = {
    name: '_blank',
    specs: [
        'fullscreen=yes',
        'titlebar=yes',
        'scrollbars=yes'
    ],
    styles: [
        'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
        'https://unpkg.com/kidlat-css/css/kidlat.css'
    ],
    timeout: 1000, // default timeout before the print window appears
    autoClose: true, // if false, the window will not close after printing
    windowTitle: window.document.title, // override the window title
}

Vue.use(VueHtmlToPaper, options);

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import { ReactiveFormConfig, ClientLibrary } from '@rxweb/reactive-forms';
ReactiveFormConfig.clientLib = ClientLibrary.Vue;
ReactiveFormConfig.set({
    validationMessage: {
        required: "This Field is required",
    }
});

import Vue2Editor from "vue2-editor";
Vue.use(Vue2Editor as any);

var VueScrollTo = require('vue-scrollto');

Vue.use(VueScrollTo)

import VeeValidate from 'vee-validate';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
    faHome,
    faUser,
    faUserPlus,
    faSignInAlt,
    faSignOutAlt
} from '@fortawesome/free-solid-svg-icons';

library.add(faHome, faUser, faUserPlus, faSignInAlt, faSignOutAlt)

Vue.config.productionTip = true;

Vue.use(VeeValidate, {
    fieldsBagName: 'vee-fields',
});
Vue.component('font-awesome-icon', FontAwesomeIcon);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');

router.beforeEach((to, from, next) => {
    const publicPages = ['home', 'login', 'register', 'dettagliocanzone', 'ricercacanzone', 'configuratoreliturgia', 'ricercaconfigurazioneliturgia', 'elencocanzoniconfigurazioneliturgica'];
    const authRequired = !publicPages.includes(to.name!);
    const loggedIn = localStorage.getItem('user');

    if (loggedIn) {
        const user = JSON.parse(loggedIn);
        const isTokenExpired = isJwtExpired(user.accessToken);
        if (isTokenExpired) {
            localStorage.removeItem('user');
            next('/login');
        }
        else
            next();
    }
    else if (authRequired) {
        next('/login');
    } else {
        next();
    }
});